@import "../../assets/scss/variables.scss";

*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    background-color: $dark-color;
    position: relative;
    font-family: $font-primary;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $dark-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-background;
    border-radius: 6px;
}

.btn_main {
    background-image: url("../images/button_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 180px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in 0.1s;

    @media only screen and (max-width: 1400px) {
        width: 154px;
        height: 38px;
    }

    &:hover {
        background-image: url("../images/button_bg_hover.svg");
    }

    @media only screen and (max-width: 480px) {
        width: 150px;
        height: 35px;
    }

    button {
        color: #000;
        text-align: center;
        font-family: Rajdhani;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        outline: none;
        border: none;
        background: transparent;
        cursor: pointer;
    }
}