@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Teko:wght@300;400;500;600;700&display=swap");

// colors
$primary-color: #FFFC4C;
$secondary-color: rgba(0, 255, 255, 0.9);
$white-color: #ffffff;
$font-primary: "Teko", sans-serif;
$font-secondary: "Rajdhani", sans-serif;
$text-secondary: rgba($white-color, 0.7);
$dark-color: #000E0E;

// Bagckground color
$primary-background: #042c31;