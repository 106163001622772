@import "../../assets/scss/variables.scss";

.navbar_main_container {
  padding: 25px 0rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;

  @media screen and (max-width:1400px) {
    padding: 10px 0;
  }

  .navbar_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .left_container {
      width: 100%;
      .logo_container {
        max-width: 150px;
        display: flex;
        align-items: center;

        @media screen and (max-width:1400px) {
          max-width: 100px;
        }

        .logo {
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
    }

    .right_container {
      display: flex;

      .button_container {
        a {
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
        }
      }

      .right-menu {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        font-family: $font-secondary;
        font-weight: 600;

        @media screen and (max-width:640px) {
          display: none;
        }

        a {
          color: $white-color;
          text-transform: uppercase;
          font-size: 1.1rem;
          text-decoration: none;
          padding: 0 1rem;
          transition: all ease-in 0.1s;

          @media screen and (max-width:1400px) {
            font-size: 0.95rem;
            padding: 0 0.8rem;
          }

          &:hover {
            color: $primary-color;
          }

          &.docs-link {
            position: relative;

            .tooltip {
              background: #052D32;
              top: 118%;
              color: $primary-color;
              display: block;
              margin-bottom: 0;
              left: -30px;
              margin: auto;
              opacity: 0;
              width: 140px;
              padding: 1rem 1.2rem;
              pointer-events: none;
              position: absolute;
              white-space: nowrap;
              -webkit-transform: translateY(10px);
              -moz-transform: translateY(10px);
              -ms-transform: translateY(10px);
              -o-transform: translateY(10px);
              transform: translateY(10px);
              -webkit-transition: all .25s ease-out;
              -moz-transition: all .25s ease-out;
              -ms-transition: all .25s ease-out;
              -o-transition: all .25s ease-out;
              transition: all .25s ease-out;

              &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 10px solid #052D32;
                top: -10px;
                content: " ";
                height: 0;
                left: 50%;
                margin-left: -13px;
                position: absolute;
                width: 0;
              }
            }

            &:hover {
              .tooltip {
                opacity: 1;
                pointer-events: auto;
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                -o-transform: translateY(0px);
                transform: translateY(0px);
              }
            }

          }
        }
      }
    }
  }
}